<template>
  <div class="review-consumer">
    <el-dialog 
      :visible="dialogVisible"
      custom-class="review-consumer-dialog"
      @close="handleClose"
    >
      <div slot="title">
        {{ $t('accountManagement.consumer.ConsumerReviewDialogTitle') }}
        <el-divider />
      </div>
      <el-form
        ref="editDataForm"
        :model="editDataForm"
        label-position="top"
      > 
        <FormDisplayInDialog
          v-for="(item, index) in FormItems"
          :key="index"
          :input-label="$t(`accountManagement.${item.label}`)"
          :input-text="editDataForm[item.prop]" 
        />
        <div class="login-container">
          <el-form-item :label="$t(`accountManagement.login`)" prop="login">
            <el-input v-model="login" />
          </el-form-item>
          <CheckAccountDuplicateButton 
            :login-value="login"
            @isCheckDuplicate="handleIsCheckLoginDuplicate"
          />
        </div>
        <FormDisplayInDialog 
          :input-label="$t(`accountManagement.suggestion`)"
          :input-text="editDataForm.apply_abstract" 
        />
        <el-form-item :label="$t(`accountManagement.review_status`)" :label-width="formLabelWidth" class="radio-style">
          <el-radio-group v-model="editDataForm.review_status">
            <el-radio :label="true">{{ $t(`accountManagement.pass`) }}</el-radio>
            <el-radio :label="false">{{ $t(`accountManagement.reject`) }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="showRejectReason" :label="'*' + $t(`accountManagement.Reason`)">
          <el-input v-model="editDataForm.review_suggestion" type="textarea" prop="review_suggestion" />
          <span v-if="showNotify" class="reject-notify-container">{{ $t('accountManagement.inputReasonForReject') }}</span>
        </el-form-item>
        <div class="dialog-footer">
          <el-button type="info" plain @click="handleClose">{{ $t('general.Cancel') }}</el-button>
          <el-button type="success" plain @click="handleSummit()">{{ $t('general.Save') }}</el-button>
        </div>
        <template v-if="showUnPass">
          <div class="form-divider">
            <span>{{ $t('accountManagement.reviewRecord') }}</span>
            <el-divider />
          </div>
          <el-table
            :data="tableData"
            stripe
          >
            <el-table-column 
              v-for="(item, index) in field"
              :key="index"
              :prop="item.prop"
              :label="$t(`accountManagement.${item.label}`)"
            />
          </el-table>
        </template>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import CheckAccountDuplicateButton from '@/components/Button/CheckAccountDuplicateButton.vue'
import FormDisplayInDialog from '@/components/Dialog/FormDisplayInDialog.vue'
import { UTCtoLocalTime } from '@/utils/handleTime'
import { updateConsumerApply } from '@/api/member'
import { MemberRole, SuccessMessage } from '@/Mixins'

export default {
  name: 'ReviewConsumerDialog',
  components: {
    FormDisplayInDialog,
    CheckAccountDuplicateButton
  },
  mixins: [MemberRole, SuccessMessage],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dataDetail: {
      type: Object,
      default: () => []
    },
    activePane: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      editDataForm: {
        name: '',
        email: '',
        create_at: '',
        terms_version: '',
        phone: '',
        apply_abstract: '',
        review_status: true,
        review_suggestion: '',
        review_record: []
      },
      login: '',
      field: [
        {
          prop: 'update_at',
          label: 'dateReview'
        },
        {
          prop: 'review_suggestion',
          label: 'Reason'
        },
        {
          prop: 'auditor',
          label: 'Auditing'
        }
      ],
      FormItems: [
        {
          label: 'name',
          prop: 'name'
        },
        {
          label: 'email',
          prop: 'email'
        },
        {
          label: 'dateApply',
          prop: 'create_at'
        },
        {
          label: 'agreeVersion',
          prop: 'terms_version'
        },
        {
          label: 'phone',
          prop: 'phone'
        }
      ],
      isCheckLoginDuplicate: false,
      tableData: [],
      dataId: 0,
      formLabelWidth: '120px',
      showNotify: false
    }
  },
  computed: {
    showRejectReason() {
      return !this.editDataForm.review_status
    },
    showUnPass() {
      return this.activePane === 'reject'
    }
  },
  mounted() {
    this.setEditFormData()
  },
  methods: {
    async fetchReviewConsumer() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })

      let newObj = {}
      if (this.editDataForm.review_status) {
        newObj = {
          review_status: 'pass',
          login: this.login
        }
        this.editDataForm.review_suggestion = ''
      } else {
        newObj = {
          review_status: 'reject',
          review_suggestion: this.editDataForm.review_suggestion,
          login: this.login
        }
      }
      try {
        await updateConsumerApply(this.editDataForm.id, newObj)
        this.showSuccessMessage(this.$t('Notify.Updated'))
        loading.close()
        this.handleClose()
      } catch (error) {
        console.error(error)
        loading.close()
      }
      this.editDataForm.review_suggestion = ''
    },
    handleIsCheckLoginDuplicate(isCheck) {
      this.isCheckLoginDuplicate = isCheck
    },
    setEditFormData() {
      this.dataId = this.dataDetail.id
      this.editDataForm = this.dataDetail

      const loginStr = this.dataDetail.email.split('@')[0]
      this.login = loginStr

      this.tableData = this.editDataForm.review_record.map((item) => {
        const newObj = {
          ...item,
          update_at: UTCtoLocalTime(item.update_at, 'YYYY-MM-DD')
        }
        return newObj
      })
    },
    UTCtoLocalTime(time, format) {
      return UTCtoLocalTime(time, format)
    },
    handleSummit() {
      if (this.editDataForm.review_status) {
        if (!this.isCheckLoginDuplicate) {
          this.$message({
            title: this.$t('general.Error'),
            message: this.$t('Notify.CheckLoginDuplicate'),
            type: 'error'
          })
        } else {
          this.fetchReviewConsumer()
        }
      } else if (!this.editDataForm.review_status) {
        if (!this.editDataForm.review_suggestion) {
          this.showNotify = true
        } else {
          this.fetchReviewConsumer()
        }
      } 
    },
    handleClose() {
      this.$emit('closeDialog')
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";

::v-deep.el-form--label-top .el-form-item__label{
  padding-bottom: 0 !important;
}
::v-deep .review-consumer-dialog .el-dialog__body{
  padding-top: 0!important;
}
::v-deep.el-divider--horizontal {
  margin-bottom: 0;
}
.review-consumer{
  ::v-deep .review-consumer-dialog{
    @media (max-width: 576px) {
      width: 90% ;
      min-width: 280px;
    }
    
    .dialog-footer{
      display: flex;
      justify-content: end;
    }
    .form-divider{
      display: flex;
      span{
        width: 150px;
        padding: 0 15px;
        line-height: 60px;
      }
      .el-divider{
        margin: 30px 0;
      }
    }
    .login-container{
      position: relative;
    }
    .suggestion-container{
      position: relative;
      .suggestion-label{
        position: relative;
        height: 40px;
        line-height: 40px;
      }
      .suggestion-value{
        border: 1px solid #E4E7ED;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
        background-color: #F5F7FA;
        color: #C0C4CC;
      }
    }
    .reject-notify-container{
      color: $danger;
    }
  } 

  .radio-style{
    text-align: left;
    min-height: 40px;
  }
}
</style>
