<template>
  <div class="consumer-review-container">
    <div class="table-container">
      <el-row class="title-container">
        <el-col :span="20">
          <div class="title-text"> {{ $t('accountManagement.sidebarConsumer') }}</div>
        </el-col>
      </el-row>
      <el-tabs v-model="activePane" type="card" @tab-click="handleTabChange">
        <el-tab-pane
          :label="$t(`accountManagement.reviewPending`)"
          name="pending"
        >
          <el-table
            class="desk-pad-view"
            v-loading="loading"
            :data="tableData"
            stripe
          >
            <el-table-column
              v-for="(item, index) in tableFields_pending"
              :key="index"
              :prop="item.prop"
              :align="item.align"
              :width="item.width"
              :sortable="item.sortable"
              :label="item.label"
            />
            <el-table-column
              :label="$t(`accountManagement.edit`)"
              width="80px"
            >
              <template slot-scope="scope">
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  circle
                  plain
                  size="mini"
                  @click="handleEdit(scope.row)"
                />
              </template>
            </el-table-column>
            <template slot="empty">
              <el-empty :description="$t('general.NoData')" />
            </template>
          </el-table>
          <el-table
            class="phone-view"
            v-loading="loading"
            :data="tableData"
            stripe
          >
            <el-table-column
              prop="name"
              :label="$t(`accountManagement.name`)"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span><br>
                <span class="role-phone-style">{{ scope.row.email }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t(`accountManagement.edit`)"
              width="50px"
            >
              <template slot-scope="scope">
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  circle
                  plain
                  size="mini"
                  @click="handleEdit(scope.row)"
                />
              </template>
            </el-table-column>
            <template slot="empty">
              <el-empty :description="$t('general.NoData')" />
            </template>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          :label="$t(`accountManagement.reviewReject`)"
          name="reject"
        >
          <el-table
            class="desk-pad-view"
            v-loading="loading"
            :data="tableData"
            stripe
          >
            <el-table-column
              v-for="(item, index) in tableFields_reject"
              :key="index"
              :prop="item.prop"
              :align="item.align"
              :width="item.width"
              :sortable="item.sortable"
              :label="item.label"
            />
            <el-table-column
              :label="$t(`accountManagement.edit`)"
              width="80px"
            >
              <template slot-scope="scope">
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  circle
                  plain
                  size="mini"
                  @click="handleEdit(scope.row)"
                />
              </template>
            </el-table-column>
            <template slot="empty">
              <el-empty :description="$t('general.NoData')" />
            </template>
          </el-table>
          <el-table
            class="phone-view"
            v-loading="loading"
            :data="tableData"
            stripe
          >
            <el-table-column
              prop="name"
              :label="$t(`accountManagement.name`)"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span><br>
                <span class="role-phone-style">{{ scope.row.email }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t(`accountManagement.edit`)"
              width="50px"
            >
              <template slot-scope="scope">
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  circle
                  plain
                  size="mini"
                  @click="handleEdit(scope.row)"
                />
              </template>
            </el-table-column>
            <template slot="empty">
              <el-empty :description="$t('general.NoData')" />
            </template>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      
    </div>
    <ReviewConsumerDialog
      v-if="dialogVisible" 
      :dialog-visible="dialogVisible"
      :data-detail="dataDetail"
      :active-pane="activePane"
      @closeDialog="emitCloseDialog"
    />
  </div>
</template>

<script>
import { UTCtoLocalTime } from '@/utils/handleTime'

import ReviewConsumerDialog from './ReviewConsumerDialog.vue'

import { getConsumerApplierList } from '@/api/member'

export default {
  name: 'ConsumerReview',
  components: { ReviewConsumerDialog },
  data () {
    return {
      tableData: [],
      tableFields_pending: [
        {
          label: this.$t('accountManagement.name'),
          prop: 'name',
          align: 'center'
        },
        {
          label: this.$t('accountManagement.email'),
          prop: 'email',
          align: 'center'
        },
        {
          label: this.$t('accountManagement.version'),
          prop: 'terms_version',
          align: 'center'
        },
        {
          label: this.$t('accountManagement.dateApply'),
          prop: 'create_at',
          width: '150px',
          align: 'center'
        }
      ],
      tableFields_reject: [
        {
          label: this.$t('accountManagement.name'),
          prop: 'name',
          align: 'center'
        },
        {
          label: this.$t('accountManagement.email'),
          prop: 'email',
          align: 'center'
        },
        {
          label: this.$t('accountManagement.dateReview'),
          prop: 'update_at',
          width: '150px',
          align: 'center'
        },
        {
          label: this.$t('accountManagement.Auditing'),
          prop: 'auditor',
          align: 'center'
        }
      ],
      dialogVisible: false,
      dataDetail: {},
      loading: true,
      activePane: 'pending'
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData () {
      this.fetchGetMemberList()
    },
    async fetchGetMemberList() {
      const data = {
        review_status: this.activePane
      }
      try {
        const res = await getConsumerApplierList(data)
        this.tableData = res.data.map((item) => {
          const newObj = {
            ...item,
            review_status: true,
            create_at: UTCtoLocalTime(item.create_at, 'YYYY-MM-DD'),
            update_at: UTCtoLocalTime(item.update_at, 'YYYY-MM-DD')
          }
          return newObj
        })
      } catch (error) {
        console.log('getConsumerApplierList-error: ', error)
      }
      this.loading = false
    },
    handleEdit(row, id) {
      this.dialogVisible = true
      this.dataDetail = row
    },
    UTCtoLocalTime(time, format) {
      return UTCtoLocalTime(time, format)
    },
    emitCloseDialog() {
      this.dialogVisible = false
      this.dataDetail = {}
      this.loadData()
    },
    handleTabChange(tab, event) {
      this.fetchGetMemberList()
      console.log(tab, event)
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.consumer-review-container{
  width: 100%;

  ::v-deep .el-tabs__nav{
    float: right;
    @media (max-width: 576px) {
      float: initial;
    }
  }
  .el-divider{
    margin: 24px 0 0;
  }
  @media (min-width: 577px) { 
    .desk-pad-view{
      display: block;
    }
    .phone-view{
      display: none;
    } 
  }
  @media (max-width: 576px) { 
    .desk-pad-view{
      display: none;
    }
    .phone-view{
      display: block;
      .role-phone-style{
        font-weight: bold;
        color: $link;
      }
    }
  }
  .table-container{
    padding: 0 20px;
    
    .title-container{
      margin: 10px 0;
      .title-text{
        font-size: 20px;
        line-height: 2;
        text-align: left;
      }
      .title-right{
        text-align: end;
        .el-button {
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
