<template>
  <div class="dialog-form-container">
    <div class="dialog-form-label">
      {{ inputLabel }}
    </div>
    <div class="dialog-form-input">
      {{ inputText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormDisplayInDialog',
  props: {
    inputLabel: {
      type: String,
      default: ''
    },
    inputText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.dialog-form-container{
  position: relative;
  margin-bottom: 22px; // follow element ui
  .dialog-form-label{
    position: relative;
    height: 40px;  // follow element ui
    line-height: 40px;
  }
  .dialog-form-input{ // follow element ui
    border: 1px solid #E4E7ED; 
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: #F5F7FA;
    color: #C0C4CC;   
  }
}
</style>
